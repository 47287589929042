import { $api } from "@/services/constant";
import { HOST_API_V1 } from "@/services/env-init";
import { AxiosRequestConfig, BaseResponse } from "@/services/types";
import type { ListMateri, MateriDetailType } from "./types";

const BASE_URL_V1_ADMIN_LEARNING = HOST_API_V1 + "admin/learning";

export const getAdminLearningList = async (
  params: AxiosRequestConfig
): Promise<BaseResponse<ListMateri[]>> => {
  const res = await $api().get(BASE_URL_V1_ADMIN_LEARNING, params);
  return res.data;
};

export const getAdminLearningById = async (
  adminLearningId: string
): Promise<BaseResponse<MateriDetailType>> => {
  const res = await $api().get(
    `${BASE_URL_V1_ADMIN_LEARNING}/${adminLearningId}`
  );
  return res.data;
};

export const deleteAdminLearningById = async (adminLearningId: string) => {
  const res = await $api().delete(
    `${BASE_URL_V1_ADMIN_LEARNING}/${adminLearningId}`
  );
  return res.data;
};

export const postAdminLearning = async (
  payload: AxiosRequestConfig["data"]
) => {
  const res = await $api().post(`${BASE_URL_V1_ADMIN_LEARNING}`, payload);
  return res.data;
};

export const putAdminLearning = async (
  adminLearningId: string,
  payload: AxiosRequestConfig["data"]
) => {
  const res = await $api().put(
    `${BASE_URL_V1_ADMIN_LEARNING}/${adminLearningId}`,
    payload
  );
  return res.data;
};
